import React from 'react'
import loadable from '@loadable/component'
import { PageContextPropsType } from '../../../../i18n/i18n'
import LayoutSimpleFooter from '../../../components/LayoutSimpleFooter'
import NoIndex from '../../../components/NoIndex'
import classNames from 'classnames/bind'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as EbookCompletePageStyles from '../../../styles/EbookCompletePage.css'

// Render client-side only
const PardotScript = loadable(() => import('../../../components/scripts/PardotScript'))

const cx = classNames.bind(EbookCompletePageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  return (
    <LayoutSimpleFooter pageContext={pageContext}>
      <NoIndex
        title="KARTE Blocks 資料請求完了"
        description="KARTE Blocksの資料をご請求いただきありがとうございます。"
        path="/ebook/complete/"
      />
      <PardotScript />

      <main className={cx('Container', SiteWideStyles.Grid)}>
        <h1 className={cx('Headline', SiteWideStyles.Headline)}>資料請求ありがとうございます</h1>
        <p className={cx('Message')}>
          ご登録のメールアドレスに
          <br className={SiteWideStyles.DisplayMobileOnly} />
          資料ダウンロードURLをお送りいたしましたので、
          <br className={SiteWideStyles.DisplayMobileOnly} />
          ご確認ください。
        </p>

        <div id="blocksArea" />

        <a href="/" className={cx('BackButton')}>
          ホームに戻る
          <i className={cx('BackButton_Icon', ['angle-right-icon'])} />
        </a>
      </main>
    </LayoutSimpleFooter>
  )
}
