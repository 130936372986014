import "blocks-service-site/src/styles/vars.css.ts.vanilla.css!=!../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?{\"source\":\"Ll81ZDRkNTgwIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjMkFBQjlGOwp9Ci5fNWQ0ZDU4MSB7CiAgYmFja2dyb3VuZC1jb2xvcjogIzJCNUVBQjsKfQouXzVkNGQ1ODIgewogIGJhY2tncm91bmQtY29sb3I6ICNDNDJGMjc7Cn0KLl81ZDRkNTgzIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjMDAxMDBFOwp9Ci5fNWQ0ZDU4NCB7CiAgYmFja2dyb3VuZC1jb2xvcjogIzM1NDM0MTsKfQouXzVkNGQ1ODUgewogIGJhY2tncm91bmQtY29sb3I6ICM2NzcyNzA7Cn0KLl81ZDRkNTg2IHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjQUVCNEIzOwp9Ci5fNWQ0ZDU4NyB7CiAgYmFja2dyb3VuZC1jb2xvcjogI0UwRTJFMjsKfQouXzVkNGQ1ODggewogIGJhY2tncm91bmQtY29sb3I6ICNFQkVDRUM7Cn0KLl81ZDRkNTg5IHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjRjZGN0Y3Owp9Ci5fNWQ0ZDU4YSB7CiAgYmFja2dyb3VuZC1jb2xvcjogI0ZGRjsKfQouXzVkNGQ1OGIgewogIGJhY2tncm91bmQtY29sb3I6ICMxMkEwQTA7Cn0KLl81ZDRkNThjIHsKICBjb2xvcjogIzJBQUI5RjsKfQouXzVkNGQ1OGQgewogIGNvbG9yOiAjMkI1RUFCOwp9Ci5fNWQ0ZDU4ZSB7CiAgY29sb3I6ICNDNDJGMjc7Cn0KLl81ZDRkNThmIHsKICBjb2xvcjogIzAwMTAwRTsKfQouXzVkNGQ1OGcgewogIGNvbG9yOiAjMzU0MzQxOwp9Ci5fNWQ0ZDU4aCB7CiAgY29sb3I6ICM2NzcyNzA7Cn0KLl81ZDRkNThpIHsKICBjb2xvcjogI0FFQjRCMzsKfQouXzVkNGQ1OGogewogIGNvbG9yOiAjRTBFMkUyOwp9Ci5fNWQ0ZDU4ayB7CiAgY29sb3I6ICNFQkVDRUM7Cn0KLl81ZDRkNThsIHsKICBjb2xvcjogI0Y2RjdGNzsKfQouXzVkNGQ1OG0gewogIGNvbG9yOiAjRkZGOwp9Ci5fNWQ0ZDU4biB7CiAgY29sb3I6ICMxMkEwQTA7Cn0=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "blocks-service-site/src/styles/EbookPage.css.ts.vanilla.css!=!../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?{\"source\":\"Ll8yZDBnYnUwIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjRjZGN0Y3Owp9Ci5fMmQwZ2J1MSB7CiAgbGluZS1oZWlnaHQ6IDEuNjU7CiAgbGV0dGVyLXNwYWNpbmc6IDA7Cn0KLl8yZDBnYnUyIHsKICB3aWR0aDogOTAuNHZ3OwogIG1heC13aWR0aDogNDQwcHg7CiAgbWFyZ2luOiAwIGF1dG87CiAgcGFkZGluZzogNTNweCAwIDU2cHg7Cn0KLl8yZDBnYnUzIHsKICBtYXJnaW4tYm90dG9tOiAyMnB4OwogIGZvbnQtc2l6ZTogMjRweDsKfQouXzJkMGdidTQgewogIG1hcmdpbi10b3A6IDMycHg7Cn0KLl8yZDBnYnU1IHsKICBmb250LXNpemU6IDE2cHg7Cn0KLl8yZDBnYnU2IHsKICBtYXJnaW4tdG9wOiA2cHg7CiAgZm9udC1zaXplOiAxNHB4OwogIGxpbmUtaGVpZ2h0OiAxLjg1Owp9Ci5fMmQwZ2J1NyB7CiAgbWFyZ2luLXRvcDogMThweDsKfQouXzJkMGdidTggewogIGZvbnQtc2l6ZTogMTZweDsKICBmb250LXdlaWdodDogNjAwOwp9Ci5fMmQwZ2J1OSB7CiAgbWFyZ2luLXRvcDogNnB4OwogIGZvbnQtc2l6ZTogMTRweDsKICBjb3VudGVyLXJlc2V0OiBpdGVtOwp9Ci5fMmQwZ2J1YTpiZWZvcmUgewogIGNvbnRlbnQ6IGNvdW50ZXIoaXRlbSknLic7CiAgY291bnRlci1pbmNyZW1lbnQ6IGl0ZW07CiAgZGlzcGxheTogaW5saW5lLWJsb2NrOwogIHdpZHRoOiAxM3B4OwogIG1hcmdpbi1yaWdodDogM3B4Owp9Ci5fMmQwZ2J1YiB7CiAgZm9udC1zaXplOiAxNHB4Owp9Ci5fMmQwZ2J1OSAuXzJkMGdidWIgewogIG1hcmdpbi1sZWZ0OiAxcmVtOwp9Ci5fMmQwZ2J1YzpiZWZvcmUgewogIGNvbnRlbnQ6ICLjg7siOwogIGRpc3BsYXk6IGlubGluZS1ibG9jazsKICB3aWR0aDogMTNweDsKICBtYXJnaW4tcmlnaHQ6IDNweDsKICBtYXJnaW4tdG9wOiAycHg7Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzJkMGdidTEgewogICAgd2lkdGg6IDg5LjM3NSU7CiAgICBtYXgtd2lkdGg6IDExNDRweDsKICAgIG1hcmdpbjogMCBhdXRvOwogICAgcGFkZGluZy1ib3R0b206IDEwMHB4OwogIH0KfQpAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMDI1cHgpIHsKICAuXzJkMGdidTEgewogICAgZGlzcGxheTogZmxleDsKICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKICB9CiAgLl8yZDBnYnUyIHsKICAgIHdpZHRoOiAzOC40NjE2JTsKICAgIHBhZGRpbmc6IDkzcHggMjBweCAwIDA7CiAgICBib3gtc2l6aW5nOiBjb250ZW50LWJveDsKICB9Cn0=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var Container = '_2d0gbu1';
export var Content = '_2d0gbu2';
export var Details = '_2d0gbu4';
export var Details_Headline = '_2d0gbu5';
export var Details_Item = '_2d0gbua';
export var Details_List_Head = '_2d0gbu8';
export var Details_List_Item = '_2d0gbuc';
export var Details_List_Items = '_2d0gbub';
export var Details_List_Wrapper = '_2d0gbu7';
export var Details_Outline = '_2d0gbu9';
export var Details_Summary = '_2d0gbu6';
export var Headline = '_2d0gbu3';
export var Outer = '_2d0gbu0';