import React from 'react'
import { Helmet } from 'react-helmet'
import { useMetadata } from '../utils/use-metadata'

export type Props = {
  title: string
  description: string
  path: string
}

const SEO: React.VFC<Props> = (props) => {
  const defaultMetadata = useMetadata().site.siteMetadata
  const canonical = `${defaultMetadata.siteUrl}${props.path}`

  return (
    <Helmet>
      <title>{props.title}</title>
      <link rel="canonical" href={canonical} />
      <meta name="description" content={props.description} />

      <meta name="robots" content="noindex" />
    </Helmet>
  )
}

export default SEO
