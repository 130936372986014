import React from 'react'
import { useMatch } from '@reach/router'
import { IntlProvider } from 'react-intl'
import { PageContextPropsType } from '../../i18n/i18n'
import 'ress'
import '../styles/Global.css'
import Headers from './Headers'
import Footer from '../components/Footer'
import * as EbookPageStyles from '../styles/EbookPage.css'
import * as EbookCompletePageStyles from '../styles/EbookCompletePage.css'

type Props = {
  children: React.ReactNode
} & PageContextPropsType

const LayoutSimpleFooter: React.VFC<Props> = ({ children, pageContext }: Props) => {
  const language = pageContext.language
  const messages = pageContext.messages
  const isCompletePage = useMatch('/ebook/complete/')

  return (
    <IntlProvider locale={language} messages={messages}>
      <Headers />

      <div className={isCompletePage ? EbookCompletePageStyles.Outer : EbookPageStyles.Outer}>{children}</div>
      <Footer />
    </IntlProvider>
  )
}

export default LayoutSimpleFooter
